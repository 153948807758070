@use '@shared/util-styling/tokens';
@use '@shared/util-styling/mixins';

.react-select-container {
  $_this-container: &;

  &.react-select--is-disabled {
    .react-select {
      background-color: tokens.$mdrt-input-bg-read-only;
      border-color: tokens.$mdrt-input-border-read-only;
      color: tokens.$mdrt-input-text-read-only;
    }
  }

  .react-select {
    $_this: &;

    @include mixins.input;
    @include mixins.ellipsis;

    min-height: 48px;
    padding-block: tokens.$mdrt-input-padding-vertical;
    padding-inline: tokens.$mdrt-input-padding-horizontal var(--select-caret-space-size);

    &__input-container {
      margin: 0;
      overflow: hidden;
      padding: 0;
    }

    &__single-value {
      margin: 0;
    }

    &__multi-value {
      &:first-child {
        margin-left: 0;
      }

      background-color: tokens.$mdrt-chips-filter-chips-bg-fill;
      color: tokens.$mdrt-chips-filter-chips-text-fill;

      &:hover {
        background: var(--mdrt-color-backgrounds-bg-neutral-hover);
        color: var(--mdrt-color-text-on-light-secondary);
      }

      &__remove {
        cursor: pointer;

        &:hover {
          background: transparent;
          color: var(--mdrt-color-text-on-light-secondary);
        }
      }
    }

    &__indicators {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &__indicator-separator {
      background-color: var(--mdrt-color-borders-dark-02);
    }

    &__indicator {
      color: var(--mdrt-color-text-on-light-primary);

      svg {
        width: 20px;
      }

      &:hover {
        color: inherit;
      }
    }

    &__value-container {
      display: flex;
      flex-wrap: nowrap;
      padding: 0;

      &--is-multi {
        flex-wrap: wrap;
      }
    }

    &__option {
      font: var(--mdrt-body-3-long);
      padding-block: tokens.$mdrt-input-padding-vertical;

      &:hover,
      &--is-focused {
        background-color: var(--mdrt-color-backgrounds-bg-neutral-fill);
      }

      &--is-selected {
        background-color: var(--mdrt-color-backgrounds-bg-primary-selected);
        color: inherit;
      }
    }

    &__remove-selected-option-icon {
      height: var(--mdrt-size-xs);
      width: var(--mdrt-size-xs);
    }

    &:focus-within {
      @include mixins.input-focus-state;

      padding-bottom: calc(
        #{tokens.$mdrt-input-padding-vertical} - #{tokens.$mdrt-input-gap-label}
      );
      padding-top: calc(#{var(--spacing-16)} + #{tokens.$mdrt-input-gap-label});
    }

    &--has-error {
      border-color: tokens.$mdrt-input-border-error;

      &:focus-within,
      &:hover {
        border-color: transparent;
        box-shadow: 0 0 0 tokens.$mdrt-input-border-thickness-focus tokens.$mdrt-input-border-error;
      }

      #{$_this}__indicator {
        color: tokens.$mdrt-input-text-label-error;

        &:hover {
          color: tokens.$mdrt-input-text-label-error;
        }
      }
    }

    &__menu-notice--no-options {
      background-color: var(--mdrt-color-backgrounds-bg-neutral-fill);
      border-radius: var(--mdrt-corner-radius-s);
      color: var(--mdrt-color-text-on-light-primary);
      font: var(--mdrt-body-3-long);
      margin: var(--mdrt-spacing-04) var(--mdrt-spacing-08);
      padding: var(--mdrt-spacing-12);
      text-align: left;
    }
  }

  &--has-error {
    ~ label {
      color: tokens.$mdrt-input-text-label-error;
    }

    ~ svg {
      right: var(--spacing-48);
    }
  }

  &--has-error {
    &#{$_this-container}--has-value {
      ~ svg {
        right: 68px;
      }
    }
  }

  &--has-value,
  &:focus-within {
    ~ label {
      font-size: var(--font-size-2xs);
      font-weight: var(--font-weight-medium);
      top: 0;
    }
  }

  &--is-clearable {
    .react-select {
      padding-right: 73px;
    }
  }
}
