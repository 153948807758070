@use '@shared/util-styling/tokens';

$_elevation-1: var(
  --card-elevation-01,
  0 1px 2px 0 #00000029,
  0 2px 8px 0 #00000014,
  0 4px 16px 0 #0000001f
);
$_elevation-2: var(
  --card-elevation-02,
  0 2px 8px 0 #00000029,
  0 8px 16px 0 #00000014,
  0 16px 32px 0 #0000001f
);

.card {
  background-color: tokens.$mdrt-card-bg-standard;
  border: none;
  border-radius: tokens.$mdrt-card-radius-large;

  &--elevation-0 {
    box-shadow: none;
  }

  &--elevation-1 {
    box-shadow: $_elevation-1;
  }

  &--elevation-2 {
    box-shadow: $_elevation-2;
  }

  &--clickable {
    box-shadow: $_elevation-1;
    transition: box-shadow 0.1s ease-in;

    &:hover {
      box-shadow: $_elevation-2;
    }
  }

  &--secondary {
    background-color: tokens.$mdrt-card-bg-featured;
  }

  &--small-radius {
    border-radius: tokens.$mdrt-card-radius-small;
  }

  &--no-radius {
    border-radius: 0;
  }
}
