@use '@shared/util-styling/mixins';

.card-link {
  &__action {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    &:active {
      color: inherit;
    }
  }

  &__content {
    display: block;
    height: 100%;
    margin: 0;
    padding: var(--spacing-12);
    padding-top: var(--spacing-8);
    width: 100%;

    @include mixins.breakpoint('medium') {
      padding: var(--spacing-24);
      padding-top: var(--spacing-16);
    }
  }

  &__image {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    object-fit: cover;
    object-position: center center;
  }
}
