@use '@shared/util-styling/tokens';
@use '@shared/util-styling/mixins';

.carousel-card {
  $_this: &;

  background-color: tokens.$mdrt-card-bg-standard;
  display: flex;
  flex-direction: column;
  height: 524px;
  max-width: 430px;
  position: relative;

  &__content-headline {
    @include mixins.line-clamp(2);
  }

  &__action {
    display: block;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    &:active {
      color: inherit;
    }
  }

  &__badge {
    left: var(--spacing-24);
    position: absolute;
    top: var(--spacing-24);
  }

  &__image-container {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  &__image {
    border-radius: inherit;
    height: 196px;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }

  &__content {
    color: var(--mdrt-color-text-on-light-primary);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-16);
    height: 100%;
    margin: 0;
    padding: var(--spacing-20) var(--spacing-24) 0 var(--spacing-24);
    position: relative;
    width: 100%;
  }

  &__content-headline {
    font: var(--mdrt-headline-4);
  }

  &__content-description {
    font-size: var(--mdrt-font-size-2);
    line-height: var(--mdrt-line-heights-4);

    @include mixins.line-clamp(4);

    &--line-clamp-3 {
      @include mixins.line-clamp(3);
    }

    &--line-clamp-2 {
      @include mixins.line-clamp(2);
    }

    &--line-clamp-no-children {
      @include mixins.line-clamp(7);
    }
  }

  &__footer {
    padding: var(--spacing-24);
    padding-top: var(--spacing-12);
  }

  &__footer-action {
    color: tokens.$mdrt-buttons-primary-text-enabled;
    font: var(--mdrt-title-3);
    padding-bottom: var(--spacing-4);
    padding-top: tokens.$mdrt-buttons-vertical-padding;
    position: relative;

    &::after {
      background-color: tokens.$mdrt-buttons-primary-bg-fill;
      bottom: 0;
      content: '';
      height: 2px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  &--highlighted {
    background-color: tokens.$mdrt-card-bg-featured;

    #{$_this}__content-headline {
      color: var(--mdrt-color-text-on-dark-primary);
    }

    #{$_this}__content-description {
      color: var(--mdrt-color-text-on-dark-secondary);
    }

    #{$_this}__footer-action {
      color: var(--mdrt-color-text-on-dark-primary);
    }
  }
}
